<template src="./HeaderPageTitle.html"></template>
<style scoped src="./HeaderPageTitle.scss" lang="scss"></style>

<script>
export default {
  name: 'HeaderPageTitle',
  data: function () {
    return {
      content: ''
    }
  },
  watch: {
    $route () {
      this.setContent()
    }
  },
  created() {
    this.setContent()
  },
  methods: {
    setContent() {
      const routerName = this.$route.name || ''

      if (routerName === 'routes.massManagement') this.content = '量産管理表'
      if (routerName === 'routes.massManagement.create') this.content ='新規量産オーダーの作成'
      if (routerName === 'routes.massManagement.edit') this.content ='量産オーダーの編集'

      if (routerName === 'routes.sampleManagement') this.content = 'サンプル管理表'
      if (routerName === 'routes.sampleManagement.create') this.content = '新規サンプルオーダー'
      if (routerName === 'routes.sampleManagement.edit') this.content = 'サンプルオーダー編集'

      if (routerName === 'routes.masterManagement' ) this.content = 'マス タ管理表'
      if (routerName === 'routes.masterManagement.create' ) this.content = '新規マスタ登録'
      if (routerName === 'routes.masterManagement.edit' ) this.content = 'マスタ編集'

      if (routerName === 'routes.companyManagement.create' ) this.content = '会社情報の登録'
    }
  }
}
</script>
