<template src="./Header.html"></template>
<style scoped lang="scss" src="./Header.scss"></style>

<script>
import { ServiceFactory } from "@/services";
import { COOKIES, Permission } from "@/constants";
import HeaderPageTitle from "../../../../../components/header-page-title/HeaderPageTitle";
import VueCookies from "vue-cookies";

const AuthService = ServiceFactory.get('auth')

export default {
  name: 'Header',
  components: { HeaderPageTitle },
  data: function () {
    return {
      userRole: Number(VueCookies.get(COOKIES.role)),
      collapseShowIcon: 'el-icon-s-fold',
      collapseExpandIcon: 'el-icon-s-unfold',
      currentUser: {}
    }
  },
  props: {
    isShowSidebar: {
      type: Boolean,
    }
  },
  computed: {
    userCompany() {
      if (this.userRole === Permission.Admin) return 'マツダ社内管理画面'
      if (this.userRole === Permission.User) return this.currentUser.name + "様　管理画面"

      return []
    },

    collapseIcon() {
      if (this.isShowSideBar) return this.collapseShowIcon
      return this.collapseExpandIcon
    }
  },
  created() {
    this.getCurrentUser()
  },
  methods: {
    onClickToFoldIcon() {
      this.$emit('collapseSidebar')
    },

    getHeaderPage(headerPage) {
      this.headerPage = headerPage;
    },

    async handleLogout() {
      const isLogged = await AuthService.logout().catch(() => null)
      if (!isLogged) return

      this.$cookies.set(COOKIES.accessToken, '')
      this.$cookies.set(COOKIES.role, '')
      await this.$router.push({ name: 'routes.auth.login' })
    },
    async getCurrentUser() {
      const user = await AuthService.currentUser().catch(() => {})

      if (!user) return
      this.currentUser = user
    }
  },
}
</script>
